import React from "react"
import { Link } from 'gatsby'
import { Container, Button } from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Social from '../components/theme/social'

import Background from '../components/theme/backgroundImage'
import Logo from '../images/mcreilly-logo.svg'

export default function Home() {

  return (
    <>
      <Layout>
      <SEO title="McReilly's Pub" />
        <Background>
          <Container className="text-center white-text py-20 intro-fade">
            <img src={Logo} alt="McReilly's Logo" width="350px" className="mx-auto mb-10" />
            <h1>COMING SOON</h1>
            <h5>Our restaurant is your home away from home {';)'}</h5>
            <Link to="/menu" className="inline-flex mx-auto my-10 border border-white">
              <Button className="lowercase primary-button">check out our menu</Button>
            </Link>
            <Social />
          </Container>
        </Background>
      </Layout>
  </>
  )
}